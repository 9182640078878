export default function () {
  const menuBtn = document.querySelector('.bmenu-wrap');
  const hamburger = document.querySelector('.bhamburger');
  const bmenu = document.querySelector('.bmenu');

  // stop scroll on menu open
  const docbody = document.getElementsByTagName('BODY')[0];

  menuBtn.addEventListener('click', function () {
    hamburger.classList.toggle('rotate');
    bmenu.classList.toggle('visible');
    docbody.classList.toggle('body-no-scroll');
  });
}
